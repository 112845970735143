import { HelmetProvider } from 'react-helmet-async';

import Pages from './pages/Pages';

function App() {
  return (
    <>
      <HelmetProvider>
        <Pages />
      </HelmetProvider>
    </>
  );
}

export default App;
